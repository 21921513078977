import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';

const RevShouldAvgust: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Marsh Creation</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_marsh.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Marsh Creation</h1>
          <h2>Everything you need to know about the Marsh Creation Raid!</h2>
          <p>
            Last updated: <strong>19/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <SectionHeader title="Introduction" />
      <StaticImage
        src="../../../images/reverse/generic/boss_2.webp"
        alt="Raid Boss"
      />
      <p>
        This lady we call <strong>“Marsh Creation”</strong> (from here on out,
        just “Marsh”) and it’s the second boss of the 1.4 Mane’s Bulletin. You
        may remember this lovely lady from one of the story chapters. Its
        Afflatus is Beast, so the standard is to run Mineral oriented teams
        around, and Kaalaa Baunaa is considered the best Carry in this fight.
      </p>
      <p>
        Unlike Darkness of the Abyss, Marsh doesn’t have any mechanics that are
        as specific, and it generally stays true to its fight in Chapter 2 (with
        some added spice).
      </p>
      <ul>
        <li>
          In this fight, <strong>Mineral and Beast characters gain +20%</strong>{' '}
          to their stats.
        </li>
        <li>
          <strong>
            [Shield] effects in this fight also reduce damage taken by a certain
            amount
          </strong>
          , making shield based supports quite a bit stronger.
        </li>
        <li>
          Every 2 rounds Marsh will enter a <strong>[Counter] state</strong>.
          Trying to attack it while in this state will inflict [Corrosion] on
          the attacker, a hard hitting DoT effect. This is especially dangerous
          in Endless runs where the damage this deals can scale pretty
          dramatically. If Marsh isn’t in the [Counter] state, it takes +40%
          DMG.
        </li>
        <li>
          At the end of the round, Marsh also applies 2 random buffs from a set
          of buffs on itself. While attacking, for each [Stats Up], [Status Pos]
          or [Counter] it carries, it will deal +10% damage. This makes
          characters who can consistently dispel these things extremely
          valuable.
        </li>
        <li>
          The <strong>buffs can be dispelled</strong> by using a rank 2 or 3
          Health/Counter/Buff card.
        </li>
        <li>
          With one of its skills Marsh grants itself extra Reality DEF and, if
          it already has this buff, it increases its Mental DEF instead. The
          lower Marsh goes on its HP bars, the higher these buffs become, so
          dispelling is an important part of the fight.
        </li>
        <li>
          In Endless Mode, when Marsh attacks characters that aren’t in the
          [Shield] or [Counter] state, it deals +20% damage. This makes having
          characters that can routinely apply both of these statuses extremely
          valuable to mitigate the already high outgoing damage.
        </li>
      </ul>
      <SectionHeader title="Teambuilding" />
      <p>
        The teams are a bit more restricted in this fight, as the Mineral
        Afflatus doesn’t have many solidly offensive units under its belt at the
        time of this release. Luckily, Kaalaa Bauna has been added to the roster
        by this point, and thus she serves as the standard Carry for this fight.
      </p>
      <div className="team-box">
        <h5 className="beast">Kaalaa Baunaa TEAM</h5>
        <div className="team raid">
          <div className="role left">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="kaalaa-baunaa"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>&nbsp;</p>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="pickles"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="sonetto"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="6"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="necrologist"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sub-carry">Carry / Support</h6>
          </div>
          <div className="role left">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="medicine-pocket"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="tooth-fairy"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="ms-newbabel"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sustain">Sustain</h6>
          </div>
          <div className="role">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="balloon-party"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>Any other healer.</p>
            </div>
            <h6 className="sustain">Sustain</h6>
          </div>
        </div>
        <div className="info raid">
          <h6>TEAM EXPLANATION</h6>
          <p>
            This team focused around Kaalaa Baunaa. Unsurprisingly, the addition
            of an extra AP greatly helps to mitigate most if not all issues KB
            has in terms of cycling abilities and getting enough actions to
            charge her Ultimate. In addition, the extra team slot allows for
            some wildly varied and powerful team compositions.
          </p>
          <ul>
            <li>
              Kaalaa Baunaa proves extremely potent when given more lenient
              circumstances in the form of +1 AP, allowing her to easily stack
              her [Planet]s and Ultimate in quick succession. In combination
              with a load of [Saturn] layers, KB can output some terrifying
              numbers.
            </li>
            <li>
              Pickles is here to provide extra turns for KB to cycle her
              Ultimate and provide that potent +DMG Dealt buff to the team.
              Additionally, Pickles is one the few characters capable of
              dispelling [Counter] effects, which can be of great help in a
              pinch if you don’t happen to have access to a rank 2/3 Health or
              Counter spell. Additionally, Pickles’ single-target damage is
              surprisingly high with “Thus Spoke Pickles” and “Nihilism Abuse”
              under the effect of [Clarified Topic].
            </li>
            <li>
              Med Poc’s single-target +DMG Taken debuff synergizes greatly with
              KB’s single-target damage plus they have access to that quick
              on-demand [Sturdiness] that really helps in the Endless stages
              past S rank.
            </li>
            <li>
              Balloon Party makes an appearance here as a healer with access to
              rank 2/3 Counter spells. In addition, as the fight enters its
              later stages, Lost HP-based healing becomes more potent as attacks
              from the boss start hitting harder and harder.
            </li>
          </ul>
          <p>For other options:</p>
          <ul>
            <li>
              <strong>Sonetto</strong> - An old reliable, Sonetto serves as a
              potent buff Support that you can always fall back on. Her +DMG
              Dealt and -DMG Taken buffs provide offensive and defensive
              coverage at the same time, giving an extra protective edge over
              someone like Pickles at the cost of his dispel.
            </li>
            <li>
              <strong>6</strong> - While not available until the second phase of
              the patch, 6 on his own is a staple Support especially for Mane’s
              Bulletin. He can be used in pretty much every team due to the
              versatility of his buffs, debuffs and access to semi-on-demand
              [Incantation Empowerment] for your Carry. This especially helps
              KB, as the amount of [Planet]s she stacks depends on the rank of
              the spell used.
            </li>
            <li>
              <strong>Necrologist (P1-5</strong>) - A might surprising option
              for Pickles’ slot, Necrologist provides the biggest +DMG Dealt
              buff in the game at Portray 1 (40%) and 5 (50%) and with the extra
              AP in 4-man content, she doesn’t impede much on KB’s rotation.
              Note that she cannot dispel [Counter], so you’ll have to rely on
              other ways to remove that mechanic.
            </li>
            <li>
              <strong>Tooth Fairy</strong> - Surprisingly not used over Med Poc
              in this team, but not by much of an edge. The idea is that, with
              how hard Marsh tends to hit, more value is put on Med Poc’s
              [Sturdiness] compared to Tooth Fairy’s healing output; mitigating
              the incoming damage *&gt; trying to heal it up.
            </li>
            <li>
              <strong>Healers</strong> - As a rule of thumb, very few healers
              are restricted to particular teams, as their main role is to keep
              the team alive. It is also not uncommon for Endless teams to
              feature 2 healers, one occupying a Support slot.
            </li>
          </ul>
        </div>
      </div>
      <div className="team-box">
        <h5 className="beast">MELANIA/KAALAA TEAM</h5>
        <div className="team raid">
          <div className="role left">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="melania"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="6"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="kaalaa-baunaa"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>&nbsp;</p>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role left">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="tooth-fairy"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="ms-newbabel"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sustain">Sustain</h6>
          </div>
          <div className="role">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="medicine-pocket"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>Any other healer.</p>
            </div>
            <h6 className="sustain">Sustain</h6>
          </div>
        </div>
        <div className="info raid">
          <h6>TEAM EXPLANATION</h6>
          <p>
            This team focused around both Melania and Kaalaa Baunaa, and is, as
            you might guess by looking at the composition, focused on taking
            highly aggressive actions utilizing two ramping single-target
            Carries to quickly and consistently raise the score.
          </p>
          <ul>
            <li>
              Melania is the queen of Mane’s Bulletin, with a kit that perfectly
              synergizes with the game mode’s extended single-target fights with
              high amounts of HP. She can quickly ramp her [Fixed Plan],
              stacking up to turn her Ultimate into a fast-charging nuke. She
              semi-synergizes with KB in this regard, who cannot generate her
              own Moxie as easily and requires more actions.
            </li>
            <li>
              Joining in the ramping game is Kaalaa Baunaa, who utilizes the
              extra AP to rapidly cycle her own Ultimate through her actions,
              quickly stacking up [Full Moon] to provide herself with even more
              damage and even a way to upgrade her own cards with [Incantation
              Empowerment II].
            </li>
            <li>
              Due to using 2 Carries, there is less space for the conventional
              Support. As such, Tooth Fairy is picked here to provide some
              offensive Crit-based debuffing with [Confusion], -Crit DEF and
              -Crit Resist. Additionally, with the lack of potential defensive
              Supports, her incredibly high healing output helps keep the team
              alive
            </li>
            <li>
              Medicine Pocket’s single-target +DMG Taken debuff synergizes
              greatly with both Melania and KB’s single-target damage plus they
              have access to that quick on-demand [Sturdiness] that really helps
              in the Endless stages past S rank. Especially when no defensive
              buffer is available, the [Sturdiness] carries the defensive
              coverage of this team even harder.
            </li>
            <li>
              This is a risky team, and you’ll often have to save your Health
              cards to time them with Marsh’s [Counter] state for dispelling
              purposes, which in turn results in a fragile but highly potent
              composition.
            </li>
          </ul>
          <p>For other options:</p>
          <ul>
            <li>
              <strong>6 (P1-5) </strong>- While not available until the second
              phase of the patch, 6 is a staple pick for Mane’s Bulletin. In
              this instance, he isn’t utilized as a Support as much as he is a
              Carry; the ludicrous damage modifier on it, that only gets
              increased by available debuffs and [Eureka] stacks, can work quite
              well the more Portrays you have him on.
            </li>
            <li>
              <strong>Ms NewBabel</strong> - Is a potential pick if you do not
              have access to much else in the way of sustain. Generally,
              mitigating damage is considered better than healing in Mane’s
              Bulletin the later you run in the Endless mode, and pairing
              NewBabel together with Med Poc provides powerful defensive
              coverage.{' '}
            </li>
            <li>
              <strong>Healers</strong> - As a rule of thumb, very few healers
              are restricted to particular teams, as their main role is to keep
              the team alive. It is also not uncommon for Endless teams to
              feature 2 healers, one occupying a Support slot.
            </li>
          </ul>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldAvgust;

export const Head: React.FC = () => (
  <Seo
    title="Marsh Creation | Reverse: 1999 | Prydwen Institute"
    description="Everything you need to know about the Marsh Creation Raid!"
    game="reverse"
  />
);
